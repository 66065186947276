import type {ValueType} from '@github-ui/custom-properties-types'
import type {FilterKey, FilterProvider, SuppliedFilterProviderOptions} from '@github-ui/filter'
import {FilterProviderType, TRUE_FALSE_FILTER_VALUES} from '@github-ui/filter'
import {StaticFilterProvider} from '@github-ui/filter/providers'
import {NoteIcon} from '@primer/octicons-react'

export interface PropertyDefinition {
  propertyName: string
  allowedValues?: string[] | null
  required?: boolean
  valueType: ValueType
}

interface CustomPropertiesFilterOptions {
  /**
   * Determines if properties are shown in the suggestion box by default.
   * Defaults to NOT_SHOWN.
   **/
  priority?: number
  /**
   * Whether `no:` qualifier is supported.
   * Takes effect only for non-required properties.
   * Defaults to true.
   **/
  valueless?: boolean
  /**
   * Whether the same property name is suggested multiple times.
   * Multiple keys in the query describe an `AND` condition.
   * Takes effect only for properties of type `multi_select`.
   * Defaults to true.
   **/
  multiKey?: boolean
}

export function getCustomPropertiesProviders(
  definitions: PropertyDefinition[],
  options: Partial<CustomPropertiesFilterOptions> = {},
) {
  return definitions.map(definition => new CustomPropertyFilterProvider(definition, options))
}

const NOT_SHOWN = 10

class CustomPropertyFilterProvider extends StaticFilterProvider {
  constructor(definition: PropertyDefinition, options: Partial<CustomPropertiesFilterOptions>) {
    const {propertyName, valueType} = definition

    const allowedValues = definition.allowedValues || []
    const filter: FilterKey = {
      displayName: `Property: ${propertyName}`,
      key: `props.${propertyName}`,
      priority: options.priority ?? NOT_SHOWN,
      icon: NoteIcon,
      aliases: [`p.${propertyName}`, `properties.${propertyName}`],
    }

    const values = allowedValues.map((value, index) => ({value, displayName: value, priority: index + 1}))

    const filterValues = valueType === 'true_false' ? TRUE_FALSE_FILTER_VALUES : values
    const filterOptions = getFilterOptions(definition, options)

    super(filter, filterValues, filterOptions)

    this.type = this.filterProviderType(valueType)
  }

  filterProviderType = (valueType: ValueType) => {
    switch (valueType) {
      case 'single_select':
        return FilterProviderType.Select
      case 'multi_select':
        return FilterProviderType.Select
      case 'true_false':
        return FilterProviderType.Boolean
      case 'string':
        return FilterProviderType.Text
    }
  }
}

function getFilterOptions(
  {valueType, required}: PropertyDefinition,
  options: Partial<CustomPropertiesFilterOptions>,
): SuppliedFilterProviderOptions {
  return {
    filterTypes: {
      multiKey: valueType === 'multi_select' ? options.multiKey ?? true : false,
      multiValue: true,
      valueless: required ? false : options.valueless ?? true,
    },
  }
}

export function isCustomPropertyProvider(provider: FilterProvider): provider is CustomPropertyFilterProvider {
  return provider instanceof CustomPropertyFilterProvider
}

export function isCustomPropertiesKey(key: string) {
  const lowerKey = key.toLowerCase()
  return lowerKey.startsWith('properties.') || lowerKey.startsWith('props.') || lowerKey.startsWith('p.')
}
