import {type FilterKey, FilterProviderType} from '@github-ui/filter'
import {StaticFilterProvider} from '@github-ui/filter/providers'
import {CodeIcon} from '@primer/octicons-react'

export class LanguageStaticFilterProvider extends StaticFilterProvider {
  constructor() {
    const values = LANGUAGES.map(({name, color}) => ({
      value: name,
      displayName: name,
      color,
      priority: POPULAR_LANGUAGES.includes(name) ? 5 : 10,
      iconColor: color,
    }))
    // Regardless of priority, they are displayed by order of appearance
    values.sort((a, b) => a.priority - b.priority)
    super(LANGUAGES_FILTER, values, {filterTypes: {multiKey: false, multiValue: true, valueless: false}})
    this.type = FilterProviderType.Select
  }
}

export type ReposLanguageProviderKey = 'language'
const LANGUAGES_FILTER: FilterKey = {
  displayName: 'Language',
  key: 'language',
  description: 'Programming language',
  priority: 3,
  icon: CodeIcon,
}

interface Language {
  name: string
  color: string
  priority?: number
}

const POPULAR_LANGUAGES = ['C++', 'Go', 'Java', 'JavaScript', 'PHP', 'Python', 'Ruby', 'TypeScript']

// NOTE: this is not intended to be an exhaustive list of supported languages,
// but just a list suitable to be rendered as suggestions. The list is copied
// from Blackbird frontend (which is not reference-able from ui/packages):
// app/components/search/suggestions/languages.ts
export const LANGUAGES: Language[] = [
  {name: 'ABAP', color: '#E8274B'},
  {name: 'AGS Script', color: '#B9D9FF'},
  {name: 'AMPL', color: '#E6EFBB'},
  {name: 'ANTLR', color: '#9DC3FF'},
  {name: 'API Blueprint', color: '#2ACCA8'},
  {name: 'APL', color: '#5A8164'},
  {name: 'ASP', color: '#6a40fd'},
  {name: 'ATS', color: '#1ac620'},
  {name: 'ActionScript', color: '#882B0F'},
  {name: 'Ada', color: '#02f88c'},
  {name: 'Agda', color: '#315665'},
  {name: 'Alloy', color: '#64C800'},
  {name: 'AngelScript', color: '#C7D7DC'},
  {name: 'AppleScript', color: '#101F1F'},
  {name: 'Arc', color: '#aa2afe'},
  {name: 'AspectJ', color: '#a957b0'},
  {name: 'Assembly', color: '#6E4C13'},
  {name: 'Asymptote', color: '#4a0c0c'},
  {name: 'AutoHotkey', color: '#6594b9'},
  {name: 'AutoIt', color: '#1C3552'},
  {name: 'Ballerina', color: '#FF5000'},
  {name: 'Batchfile', color: '#C1F12E'},
  {name: 'BlitzMax', color: '#cd6400'},
  {name: 'Boo', color: '#d4bec1'},
  {name: 'C', color: '#555555'},
  {name: 'C++', color: '#f34b7d'},
  {name: 'C#', color: '#178600'},
  {name: 'CSS', color: '#563d7c'},
  {name: 'Ceylon', color: '#dfa535'},
  {name: 'Chapel', color: '#8dc63f'},
  {name: 'Cirru', color: '#ccccff'},
  {name: 'Clarion', color: '#db901e'},
  {name: 'Clean', color: '#3F85AF'},
  {name: 'Click', color: '#E4E6F3'},
  {name: 'Clojure', color: '#db5855'},
  {name: 'CoffeeScript', color: '#244776'},
  {name: 'ColdFusion', color: '#ed2cd6'},
  {name: 'Common Lisp', color: '#3fb68b'},
  {name: 'Common Workflow Language', color: '#B5314C'},
  {name: 'Component Pascal', color: '#B0CE4E'},
  {name: 'Crystal', color: '#000100'},
  {name: 'Cuda', color: '#3A4E3A'},
  {name: 'D', color: '#ba595e'},
  {name: 'DM', color: '#447265'},
  {name: 'Dart', color: '#00B4AB'},
  {name: 'DataWeave', color: '#003a52'},
  {name: 'Dhall', color: '#dfafff'},
  {name: 'Dockerfile', color: '#384d54'},
  {name: 'Dogescript', color: '#cca760'},
  {name: 'Dylan', color: '#6c616e'},
  {name: 'E', color: '#ccce35'},
  {name: 'ECL', color: '#8a1267'},
  {name: 'EQ', color: '#a78649'},
  {name: 'Eiffel', color: '#946d57'},
  {name: 'Elixir', color: '#6e4a7e'},
  {name: 'Elm', color: '#60B5CC'},
  {name: 'Emacs Lisp', color: '#c065db'},
  {name: 'EmberScript', color: '#FFF4F3'},
  {name: 'Erlang', color: '#B83998'},
  {name: 'F#', color: '#b845fc'},
  {name: 'F*', color: '#572e30'},
  {name: 'FLUX', color: '#88ccff'},
  {name: 'Factor', color: '#636746'},
  {name: 'Fancy', color: '#7b9db4'},
  {name: 'Fantom', color: '#14253c'},
  {name: 'Forth', color: '#341708'},
  {name: 'Fortran', color: '#4d41b1'},
  {name: 'FreeMarker', color: '#0050b2'},
  {name: 'Frege', color: '#00cafe'},
  {name: 'G-code', color: '#D08CF2'},
  {name: 'GDScript', color: '#355570'},
  {name: 'Game Maker Language', color: '#71b417'},
  {name: 'Genie', color: '#fb855d'},
  {name: 'Gherkin', color: '#5B2063'},
  {name: 'Glyph', color: '#c1ac7f'},
  {name: 'Gnuplot', color: '#f0a9f0'},
  {name: 'Go', color: '#00ADD8'},
  {name: 'Groovy', color: '#e69f56'},
  {name: 'HTML', color: '#e34c26'},
  {name: 'Hack', color: '#878787'},
  {name: 'Harbour', color: '#0e60e3'},
  {name: 'Haskell', color: '#5e5086'},
  {name: 'Haxe', color: '#df7900'},
  {name: 'HCL', color: '#844fba'},
  {name: 'HiveQL', color: '#dce200'},
  {name: 'HolyC', color: '#ffefaf'},
  {name: 'Hy', color: '#7790B2'},
  {name: 'IDL', color: '#a3522f'},
  {name: 'Idris', color: '#b30000'},
  {name: 'Io', color: '#a9188d'},
  {name: 'Ioke', color: '#078193'},
  {name: 'Isabelle', color: '#FEFE00'},
  {name: 'J', color: '#9EEDFF'},
  {name: 'Java', color: '#b07219'},
  {name: 'JavaScript', color: '#f1e05a'},
  {name: 'JSONiq', color: '#40d47e'},
  {name: 'Jolie', color: '#843179'},
  {name: 'Jsonnet', color: '#0064bd'},
  {name: 'Julia', color: '#a270ba'},
  {name: 'Jupyter Notebook', color: '#DA5B0B'},
  {name: 'KRL', color: '#28430A'},
  {name: 'Kotlin', color: '#F18E33'},
  {name: 'LFE', color: '#4C3023'},
  {name: 'LLVM', color: '#185619'},
  {name: 'LSL', color: '#3d9970'},
  {name: 'Lasso', color: '#999999'},
  {name: 'Lex', color: '#DBCA00'},
  {name: 'LiveScript', color: '#499886'},
  {name: 'LookML', color: '#652B81'},
  {name: 'Lua', color: '#000080'},
  {name: 'MATLAB', color: '#e16737'},
  {name: 'MAXScript', color: '#00a6a6'},
  {name: 'MQL4', color: '#62A8D6'},
  {name: 'MQL5', color: '#4A76B8'},
  {name: 'MTML', color: '#b7e1f4'},
  {name: 'Makefile', color: '#427819'},
  {name: 'Markdown', color: '#083fa1'},
  {name: 'Mask', color: '#f97732'},
  {name: 'Max', color: '#c4a79c'},
  {name: 'Mercury', color: '#ff2b2b'},
  {name: 'Meson', color: '#007800'},
  {name: 'Metal', color: '#8f14e9'},
  {name: 'Mirah', color: '#c7a938'},
  {name: 'Modula-3', color: '#223388'},
  {name: 'NCL', color: '#28431f'},
  {name: 'Nearley', color: '#990000'},
  {name: 'Nemerle', color: '#3d3c6e'},
  {name: 'NetLinx', color: '#0aa0ff'},
  {name: 'NetLinx+ERB', color: '#747faa'},
  {name: 'NetLogo', color: '#ff6375'},
  {name: 'NewLisp', color: '#87AED7'},
  {name: 'Nextflow', color: '#3ac486'},
  {name: 'Nim', color: '#37775b'},
  {name: 'Nit', color: '#009917'},
  {name: 'Nix', color: '#7e7eff'},
  {name: 'Nu', color: '#c9df40'},
  {name: 'OCaml', color: '#3be133'},
  {name: 'ObjectScript', color: '#424893'},
  {name: 'Objective-C', color: '#438eff'},
  {name: 'Objective-C++', color: '#6866fb'},
  {name: 'Objective-J', color: '#ff0c5a'},
  {name: 'Omgrofl', color: '#cabbff'},
  {name: 'Opal', color: '#f7ede0'},
  {name: 'Oxygene', color: '#cdd0e3'},
  {name: 'Oz', color: '#fab738'},
  {name: 'P4', color: '#7055b5'},
  {name: 'PLSQL', color: '#dad8d8'},
  {name: 'Pan', color: '#cc0000'},
  {name: 'Papyrus', color: '#6600cc'},
  {name: 'Parrot', color: '#f3ca0a'},
  {name: 'Pascal', color: '#E3F171'},
  {name: 'Pawn', color: '#dbb284'},
  {name: 'Pep8', color: '#C76F5B'},
  {name: 'Perl', color: '#0298c3'},
  {name: 'Perl 6', color: '#0000fb'},
  {name: 'PHP', color: '#4F5D95'},
  {name: 'PigLatin', color: '#fcd7de'},
  {name: 'Pike', color: '#005390'},
  {name: 'PogoScript', color: '#d80074'},
  {name: 'PostScript', color: '#da291c'},
  {name: 'PowerBuilder', color: '#8f0f8d'},
  {name: 'PowerShell', color: '#012456'},
  {name: 'Processing', color: '#0096D8'},
  {name: 'Prolog', color: '#74283c'},
  {name: 'Propeller Spin', color: '#7fa2a7'},
  {name: 'Puppet', color: '#302B6D'},
  {name: 'PureBasic', color: '#5a6986'},
  {name: 'PureScript', color: '#1D222D'},
  {name: 'Protocol Buffers', color: '#CCCCCC'},
  {name: 'Python', color: '#3572A5'},
  {name: 'QML', color: '#44a51c'},
  {name: 'Quake', color: '#882233'},
  {name: 'R', color: '#198CE7'},
  {name: 'RAML', color: '#77d9fb'},
  {name: 'Racket', color: '#3c5caa'},
  {name: 'Ragel', color: '#9d5200'},
  {name: 'Rascal', color: '#fffaa0'},
  {name: 'Rebol', color: '#358a5b'},
  {name: 'Red', color: '#f50000'},
  {name: "Ren'Py", color: '#ff7f7f'},
  {name: 'Ring', color: '#2D54CB'},
  {name: 'Roff', color: '#ecdebe'},
  {name: 'Rouge', color: '#cc0088'},
  {name: 'Ruby', color: '#701516'},
  {name: 'Rust', color: '#dea584'},
  {name: 'SAS', color: '#B34936'},
  {name: 'SQF', color: '#3F3F3F'},
  {name: 'SRecode Template', color: '#348a34'},
  {name: 'SaltStack', color: '#646464'},
  {name: 'Scala', color: '#c22d40'},
  {name: 'Scheme', color: '#1e4aec'},
  {name: 'Self', color: '#0579aa'},
  {name: 'Shell', color: '#89e051'},
  {name: 'Shen', color: '#120F14'},
  {name: 'Slash', color: '#007eff'},
  {name: 'Slice', color: '#003fa2'},
  {name: 'Smalltalk', color: '#596706'},
  {name: 'Solidity', color: '#AA6746'},
  {name: 'SourcePawn', color: '#5c7611'},
  {name: 'Squirrel', color: '#800000'},
  {name: 'Stan', color: '#b2011d'},
  {name: 'Standard ML', color: '#dc566d'},
  {name: 'SuperCollider', color: '#46390b'},
  {name: 'Swift', color: '#ffac45'},
  {name: 'SystemVerilog', color: '#DAE1C2'},
  {name: 'TI Program', color: '#A0AA87'},
  {name: 'Tcl', color: '#e4cc98'},
  {name: 'TeX', color: '#3D6117'},
  {name: 'Terra', color: '#00004c'},
  {name: 'Turing', color: '#cf142b'},
  {name: 'TypeScript', color: '#2b7489'},
  {name: 'UnrealScript', color: '#a54c4d'},
  {name: 'VCL', color: '#148AA8'},
  {name: 'VHDL', color: '#adb2cb'},
  {name: 'Vala', color: '#fbe5cd'},
  {name: 'Verilog', color: '#b2b7f8'},
  {name: 'Vim script', color: '#199f4b'},
  {name: 'Visual Basic', color: '#945db7'},
  {name: 'Volt', color: '#1F1F1F'},
  {name: 'Vue', color: '#2c3e50'},
  {name: 'WebAssembly', color: '#04133b'},
  {name: 'Wollok', color: '#a23738'},
  {name: 'X10', color: '#4B6BEF'},
  {name: 'XC', color: '#99DA07'},
  {name: 'XQuery', color: '#5232e7'},
  {name: 'XSLT', color: '#EB8CEB'},
  {name: 'YARA', color: '#220000'},
  {name: 'YASnippet', color: '#32AB90'},
  {name: 'Yacc', color: '#4B6C4B'},
  {name: 'ZAP', color: '#0d665e'},
  {name: 'ZIL', color: '#dc75e5'},
  {name: 'ZenScript', color: '#00BCD1'},
  {name: 'Zephir', color: '#118f9e'},
  {name: 'Zig', color: '#ec915c'},
  {name: 'eC', color: '#913960'},
  {name: 'mcfunction', color: '#E22837'},
  {name: 'nesC', color: '#94B0C7'},
  {name: 'ooc', color: '#b0b77e'},
  {name: 'sed', color: '#64b970'},
  {name: 'wdl', color: '#42f1f4'},
  {name: 'wisp', color: '#7582D1'},
  {name: 'xBase', color: '#403a40'},
]
